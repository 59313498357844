import { RootState } from '@/store/state'

export default {
	getLabelsOptions: (state: RootState): Array<{ label: string, value: number }> => {
		return Object.entries(state.boardsState.activeBoard!.config.labels).map(([ index, name ]) => {
			return {
				label: name,
				value: Number(index)
			}
		})
	},
	labels: (state: RootState): { [key: number]: string } => {
		return state.boardsState.activeBoard!.config.labels
	}
}
