import { RootState } from '@/store/state'
import { Board, BoardAdmin, SlimBoardInfo, SlimChannelInfo } from '@/includes/types/Board.types'
import { BoardPmConfig } from '@/includes/types/PmConfig.types'

import { AutoReplyAction } from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'

import { merge } from 'lodash'

export default {
    update_board_channel(state: RootState, data: { board: SlimBoardInfo['board'], channelId: SlimChannelInfo['id'], state: SlimChannelInfo['test_state'] }) {
        const board = state.boardsState.boards?.find(b => b.board === data.board)

        if (board) {
            const channelIndex = board.channels.findIndex(c => c.id === data.channelId)

            if (channelIndex !== -1) {
                const channel = board.channels[channelIndex]

                channel.test_state = data.state
                board.channels.splice(channelIndex, 1, channel)
            }
        }
    },
    set_boards_to_store(state: RootState, boards: Array<SlimBoardInfo>): void {
        state.boardsState.boards = boards
    },
    set_board_to_store(state: RootState, board: SlimBoardInfo): void {
        const storeBoards = state.boardsState.boards
        const currentBoard = state.boardsState.activeBoard

        if (storeBoards) {
            const index = storeBoards.findIndex((b) => b.board === board.board)

            if (index !== -1) {
                storeBoards.splice(index, 1, board)

                if (currentBoard) {
                    merge(currentBoard, board)
                }

            } else {
                storeBoards.push(board)
            }
        }
    },
    set_active_board_config(state: RootState, payload: Board): void {
        state.boardsState.activeBoard = payload
    },
    UPDATE_BOARD_CONFIG_FIELDS(state: RootState, payload: Partial<Board['config']>): void {
        if (state.boardsState.activeBoard) {
            state.boardsState.activeBoard.config = merge(payload)
        }
    },
    clear_active_board_config(state: RootState): void {
        state.boardsState.activeBoard = null
    },
    update_board_admins(state: RootState, boardAdmins: Array<BoardAdmin>): void {
        if (state.boardsState.activeBoard) {
            state.boardsState.activeBoard.admins = boardAdmins
        }
    },
    set_temp_trigger(state: RootState, trigger: any): void {
        state.tempTrigger = trigger
    },
    reset_temp_trigger(state: RootState): void {
        state.tempTrigger = null
    },
    update_board_channel_sub_type(state: RootState, payload: any): void {
        const board = state.boardsState.boards!.find(b => b.board === payload.boardKey)

        if (board) {
            const target = board.channels.find(t => t.id === payload.target.id)

            if (target) {
                target.limited_subscription_type = payload.target.limited_subscription_type
            }
        }

    },

    update_reply_action(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            const commands = board.config.pm_config.user_auto_reply_actions
            const index = commands.findIndex((c) => c.guid === command.guid)

            if (index !== -1) {
                commands.splice(index, 1, command)
            } else {
                commands.push(command)
            }
        }
    },
    remove_reply_action(state: RootState, guid: string): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            const commands = board.config.pm_config.user_auto_reply_actions
            const index = commands.findIndex((c) => c.guid === guid)

            if (index !== -1) {
                commands.splice(index, 1)
            }
        }
    },
    add_reply_action(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            board.config.pm_config.user_auto_reply_actions.push(command)
        }
    },
    update_pm_command(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.commands) {
            const commands = board.config.pm_config.commands
            const index = commands.findIndex((c) => c.guid === command.guid)

            if (index !== -1) {
                commands.splice(index, 1, command)
            } else {
                commands.push(command)
            }
        }
    },
    remove_pm_command(state: RootState, guid: string): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.commands) {
            const commands = board.config.pm_config.commands
            const index = commands.findIndex((c) => c.guid === guid)

            if (index !== -1) {
                commands.splice(index, 1)
            }
        }
    },
    set_private_message_settings(state: RootState, config: BoardPmConfig): void {
        state.boardsState.activeBoard!.config.pm_config = config
    }
}
