import { SelectOption } from 'piramis-base-components/src/logic/types'
import { RootState } from '@/store/state'
import i18n from '@/i18n/i18n'
import {
    PlaceholdersStack,
    PlaceholdersType
} from 'piramis-base-components/src/components/Editor/components/Toolbar/types'
import store from '@/store/store'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'
import { snakeCase } from 'lodash'
import { hierarchyPlaceholderProperties, setPlaceholderPath, sortProperties } from 'piramis-base-components/src/components/BotProperties/utils'

export default {
    hasFlows: (state: RootState): boolean => {
        if (state.flowsState.flows === null) {
            return store.getters.getMenuOptionValue(MenuOptionsKeys.Flows)
        }

        return !!state.flowsState.flows && state.flowsState.flows.length > 0
    },
    flowOptions: (state: RootState, getters): Array<SelectOption> => {
        if (state.flowsState.flows) {

            return state.flowsState.flows!.slice().map(f => ({
                label: f.title,
                value: f.key
            }))
        }

        return []
    },
    flowPlaceholders: (state: RootState): PlaceholdersStack => {
        return {
            type: PlaceholdersType.Stack,
            placeholders: [
                {
                    sections: [
                        {
                            buttonTitle: i18n.t('placeholders_user_properties_section_title').toString(),
                            placeholders: [
                                {
                                    label: i18n.t('user_placeholder_username').toString(),
                                    value: '{username}'
                                },
                                {
                                    label: i18n.t('user_placeholder_user_id').toString(),
                                    value: '{user_id}'
                                },
                                {
                                    label: i18n.t('user_placeholder_userlogin').toString(),
                                    value: '{userlogin}'
                                },
                            ]
                        },
                        {
                            buttonTitle: i18n.t('placeholders_system_properties_section_title').toString(),
                            placeholders: (state.AdminTemplate?.runtime_config?.system_properties || [])
                                .map((prop) => {
                                    return {
                                        label: i18n.t(`${ snakeCase(prop.name) }_option`).toString(),
                                        value: `{${ prop.name }}`
                                    }
                                }),
                        },
                        {
                            buttonTitle: i18n.t('placeholders_bot_properties_section_title').toString(),
                            placeholders: hierarchyPlaceholderProperties(i18n, setPlaceholderPath(sortProperties(state.boardsState.activeBoard!.properties))),
                        },
                        {
                            buttonTitle: i18n.t('bot_invite_links_placeholders').toString(),
                            placeholders: [
                                {
                                    label: i18n.t('bot_referral_link').toString(),
                                    value: '{referral_link}'
                                },
                            ]
                        },
                    ]
                },
            ]
        }
    },

    botTargets: (state: RootState, getters: any): Array<{ title: string, login: string, id: number }> => {
        const id = state.boardsState.activeBoard!.board.split('.')[0]

        const board = getters.getBoardById(id)

        if (board) {
            return getters.activeBoardChannels.map((target) => {
                return {
                    title: target.title,
                    login: target.username,
                    id: target.id
                }
            })
        }

        return []
    },
}
