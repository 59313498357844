import { RootState } from '@/store/state'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

import { CashRegisterModel, CashRegistersState } from "piramis-base-components/src/components/Shop/CashRegisters/types";
import { Product } from 'piramis-base-components/src/components/Shop/Products/types'

export default {
	hasCashRegisters: (state: RootState, getters) => {
		if (state.shopState.cashRegisters === null) {
			return getters.getMenuOptionValue(MenuOptionsKeys.Shop)
		}

		return state.shopState.cashRegisters && state.shopState.cashRegisters.length > 0
	},
	getProductsOptions: (state: RootState): Array<{ label: string, value: number }> => {
		return state.shopState.products!.map((product) => {
			return {
				label: product.name,
				value: product.id
			}
		})
	},
	getProducts: (state: RootState): Array<Product> => {
		if (state.shopState.products) {
			return state.shopState.products
		}
		
		return []
	},
	getCashRegister: (state: RootState): Array<CashRegisterModel> => {
		if (state.shopState.cashRegisters) {
			return state.shopState.cashRegisters
		}

		return []
	},
	getCashRegisterOptions: (state: RootState): Array<{ label: string, value: number }> => {
		return state.shopState.cashRegisters!.map((cashRegister) => {
			return {
				label: cashRegister.name,
				value: cashRegister.id,
			}
		})
	},
	getActiveCashRegisterOptions: (state: RootState): Array<{ label: string, value: number }> => {
		if (state.shopState.cashRegisters) {
			return state.shopState.cashRegisters
				.filter((cashRegister) => cashRegister.state === CashRegistersState.Active)
				.map((cashRegister) => {
					return {
						label: cashRegister.name,
						value: cashRegister.id,
					}
				})
		}

		return []
	},
}
