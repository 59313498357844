import { RootState } from '@/store/state'
import { ChannelInfo, SlimBoardInfo, TargetState } from '@/includes/types/Board.types'
import { BoardTargetOptionsType, GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import moment from 'moment'

export default {
    isBoardSet: (state: RootState): boolean => !!state.boardsState.activeBoard,
    activeBoard: (state: RootState): SlimBoardInfo | null => state.boardsState.activeBoard,
    hasBoardStyles: (state: RootState, getters) => {
      if (getters.isBoardSet) {
        return getters.activeBoard.config.styles && Object.keys(getters.activeBoard.config.styles).length
      }

      return false
    },
    getBoardById: (state: RootState): GetBoardByIdType => (boardId: string) => {
        return state.boardsState.boards!.find(b => b.board.split('.')[0] === boardId)
    },
    activeBoardChannels: (state: RootState, getters): Array<ChannelInfo> => {
        if (getters.isBoardSet) {
            return getters.activeBoard.channels.filter(c => c.state === TargetState.Board)
        }

        return []
    },
    boardTargetOptions: (state: RootState, getters): BoardTargetOptionsType => (boardId: string): Array<SelectOptionData> => {
        if (getters.isBoardSet) {
            return getters.activeBoardChannels.map((target: ChannelInfo) => {
                return {
                    'label': target.title,
                    'value': target.id,
                    'image': {
                        'src': target.avatar
                    }
                }
            })
        }

        return []
    },
    boardAdmins: (state: RootState, getters): Array<SelectOptionData> => {
        if (getters.isBoardSet) {
            return state.boardsState.activeBoard!.admins.map((a) => {
                return {
                    'label': a.name,
                    'value': a.user_id,
                }
            })
        }

        return []
    },

    sortedBoards: (state: RootState): Array<SlimBoardInfo> => {
        if (state.boardsState.boards) {
            return state.boardsState.boards.sort((a, b) => {
                if (a.channels.length > b.channels.length || a.weight > b.weight) {
                    return -1
                }
                if (a.channels.length < b.channels.length || a.weight < b.weight) {
                    return 1
                }
                return 0
            })
        }

        return []
    },
    stylesOptions: (state: RootState): Array<SelectOptionData> => {
        const board = state.boardsState.activeBoard
        const options: Array<SelectOptionData> = [
            { label: '-', value: '' }
        ]

        if (board?.config.styles && Object.keys(board?.config.styles).length) {
            options.push(
                    ...Object.keys(board?.config.styles).map((styleName: string) => ({
                    label: styleName,
                    value: styleName
                }))
            )

            return options
        }

        return []
    },
    boardLicenseType: (state:RootState) => {
        const board = state.boardsState.activeBoard

        const LICENSE_TYPES = {
            '0': '',
            '1': 'Prime'
        }

        if (board) {
            return LICENSE_TYPES[board.license_type]
        }
    },
    hasBoardLicense: (state:RootState, getters):boolean => {
        if (getters.isBoardSet) {
            return moment(getters.activeBoard.license_time).isAfter(moment())
        }

        return false
    },
    boardLicenseExpires: (state:RootState, getters):boolean => {
        if (getters.isBoardSet) {
            return moment(getters.activeBoard.license_time).diff(moment(), 'd') < 7
                && moment(getters.activeBoard.license_time).isSameOrAfter(moment())
        }

        return false
    },
    hasBotModerators: (state:RootState, getters):boolean => {
        if (getters.isBoardSet) {
            return getters.activeBoard.admins.some((admin) => admin.limits !== null)
        }

        return false
    },
}
